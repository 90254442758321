










import { defineComponent, ref } from "@vue/composition-api";
import LoadingButton from "@/shared/components/LoadingButton";
import { RasmusUserLogoutApiData } from ".";

import {
  rasmusRequest,
  redirectToPath,
} from "@/features/rasmus-kids-club/shared/helpers";

export default defineComponent({
  name: "RasmusUserLogin",
  components: {
    LoadingButton,
  },
  setup() {
    let isSubmitting = ref(false);

    const logout = async () => {
      isSubmitting.value = true;
      const response = await rasmusRequest<RasmusUserLogoutApiData>({
        action: "LOGOUT",
      });
      isSubmitting.value = false;

      if (response.success) {
        redirectToPath(response.data?.redirectUrl);
      }
    };

    return {
      logout,
      isSubmitting,
    };
  },
});
