/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";

import { RasmusUserLogout } from "@/features/rasmus-kids-club/users/logout";

export const initRasmusUserLogout = () => {
  const el = document.getElementById("rasmus-user-logout");

  if (!el) {
    return;
  }

  try {
    new Vue({
      el,
      render: (h) =>
        h(RasmusUserLogout, {
          props: {},
        }),
    });
  } catch (err) {
    console.error('Failed to load module "RasmusUserLogout"');
  }
};

initRasmusUserLogout();
